import { ErrorMessage, Form, Formik } from 'formik';
import { graphql, navigate, StaticQuery } from 'gatsby';
import React, { Component, Fragment, useState } from 'react';
import * as yup from 'yup';
import Card from '../components/card/card';
import Grid from '../components/grid/grid';
import GridItem from '../components/grid/item';
import Hero from '../components/hero/hero';
import Layout from '../components/layout';
import Loader from '../components/loader/loader';
import Snackbar from '../components/snackbar/snackbar';
import { NON_VETERINARIAN, ROLE_VET } from '../constants/roles';
import Api from '../utils/axios';
import formatGraphqlData from '../utils/gatsbyGraphql';


const initialValues = {
  role: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  password: '',
  password2: '',
  vetProfile: {
    licenseNumber: '',
    licenseExpiration: '',
    licenseFile: '',
    clinicName: '',
    clinicStreet: '',
    clinicCity: '',
    clinicZip: '',
    clinicState: '',
    deaNumber: '',
    deaFile: '',
  },
  nonVeterinarian: {
    actualRole: '',
    otherRole: '',
    organization: '',
    street: '',
    state: '',
    city: '',
    zip: '',
  },
};

const schema = yup.object().shape({
  role: yup
    .number()
    .oneOf([NON_VETERINARIAN, ROLE_VET])
    .required()
    .label('Role'),
  firstName: yup
    .string()
    .required()
    .label('First name'),
  lastName: yup
    .string()
    .required()
    .label('Last name'),
  phoneNumber: yup
    .string()
    .required()
    .label('Phone number'),
  email: yup
    .string()
    .email()
    .required()
    .label('Email'),
  password: yup
    .string()
    .min(8)
    .matches(/(\d*[a-zA-Z]+\d*)+/, { message: 'At least one character' })
    .required()
    .label('Password'),
  password2: yup
    .string()
    .min(8)
    .matches(/(\d*[a-zA-Z]+\d*)+/, { message: 'At least one character' })
    .oneOf([yup.ref('password')], 'Passwords do not match')
    .required()
    .label('Confirm password'),
  vetProfile: yup.object().when('role', {
    is: ROLE_VET,
    then: yup.object().shape({
      licenseNumber: yup
        .string()
        .required()
        .label('License number'),
      licenseExpiration: yup
        .date()
        .typeError('Please provide valid a date')
        .required()
        .label('License expiration'),
      licenseFile: yup
        .string()
        .required()
        .label('License file'),
      clinicName: yup
        .string()
        .required()
        .label('Clinic name'),
      clinicStreet: yup
        .string()
        .required()
        .label('Clinic street'),
      clinicCity: yup
        .string()
        .required()
        .label('Clinic city'),
      clinicZip: yup
        .number()
        .min(1)
        .typeError('Please provide a number')
        .required()
        .label('Clinic zip'),
      clinicState: yup
        .string()
        .required()
        .label('Clinic state'),
      deaNumber: yup.string().label('Dea number'),
      deaFile: yup.string().label('Dea file'),
    }),
  }),
  nonVeterinarian: yup.object().when('role', {
    is: NON_VETERINARIAN,
    then: yup.object().shape({
      actualRole: yup
        .string()
        .required()
        .label('Role'),
      otherRole: yup
        .string()
        .when('actualRole', {
          is: 'other',
          then: yup.string().required(),
        })
        .label('Other role'),
      organization: yup.string().label('Organization'),
      street: yup
        .string()
        .required()
        .label('Street'),
      state: yup
        .number()
        .required()
        .label('State'),
      city: yup
        .string()
        .required()
        .label('City'),
      zip: yup
        .number()
        .min(1)
        .typeError('Please provide a number')
        .required()
        .label('Zip code'),
    }),
  }),
});

const FormAsNotVet = (formAsNotVetProps) => {
  const { states, formProps } = formAsNotVetProps;
  const [showOtherInput, setShowOtherInput] = useState(false);
  const basicConditional = formProps.touched.nonVeterinarian && formProps.errors.nonVeterinarian;
  const handleCategoryChange = (formikHandleChange, e) => {
    setShowOtherInput(e.target.value === 'other');
    formikHandleChange(e);
  };

  const OtherInput = (
    <GridItem sm={12}>
      <div
        className={`form-group ${
          basicConditional
          && formProps.touched.nonVeterinarian.otherRole
          && formProps.errors.nonVeterinarian.otherRole
            ? 'form-group-invalid'
            : ''
        }`}
      >
        <label htmlFor="nonVeterinarian.otherRole">Please describe other category *</label>
        <input
          id="nonVeterinarian.otherRole"
          type="text"
          name="nonVeterinarian.otherRole"
          value={formProps.values.nonVeterinarian.otherRole}
          onChange={formProps.handleChange}
          onBlur={formProps.handleBlur}
          className="form-control"
        />
        <ErrorMessage name="nonVeterinarian.otherRole" component="div" className="error-message" />
      </div>
    </GridItem>
  );

  return (
    <Fragment>
      <Card>
        <Grid>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.nonVeterinarian.actualRole
                && formProps.errors.nonVeterinarian.actualRole
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="actualRole">Category *</label>
              <select
                id="actualRole"
                name="nonVeterinarian.actualRole"
                onChange={e => handleCategoryChange(formProps.handleChange, e)}
                onBlur={formProps.handleBlur}
                className="form-control"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Choose category here
                </option>
                <option value="vet-tech">Vet Tech</option>
                <option value="researcher-pi">Researcher - PI</option>
                <option value="wildlife-biologist">Wildlife Biologist</option>
                <option value="deer-exotics-rancher">Deer/Exotics Rancher</option>
                <option value="other">Other</option>
              </select>

              <ErrorMessage
                name="nonVeterinarian.actualRole"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.nonVeterinarian.organization
                && formProps.errors.nonVeterinarian.organization
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="nonVeterinarian.organization">Organization Name *</label>
              <input
                id="nonVeterinarian.organization"
                type="text"
                name="nonVeterinarian.organization"
                value={formProps.values.nonVeterinarian.organization}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="nonVeterinarian.organization"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          {showOtherInput ? OtherInput : null}
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.nonVeterinarian.street
                && formProps.errors.nonVeterinarian.street
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="nonVeterinarian.street">Street *</label>
              <input
                id="nonVeterinarian.street"
                type="text"
                name="nonVeterinarian.street"
                value={formProps.values.nonVeterinarian.street}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="nonVeterinarian.street"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.nonVeterinarian.state
                && formProps.errors.nonVeterinarian.state
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="nonVeterinarian.state">State *</label>
              <select
                id="nonVeterinarian.state"
                name="nonVeterinarian.state"
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Choose state here
                </option>
                {states.map((item, index) => (
                  <option value={item.strapiId} key={index.toString()}>
                    {item.name}
                  </option>
                ))}
              </select>
              <ErrorMessage
                name="nonVeterinarian.state"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.nonVeterinarian.city
                && formProps.errors.nonVeterinarian.city
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="nonVeterinarian.city">City *</label>
              <input
                id="nonVeterinarian.city"
                type="text"
                name="nonVeterinarian.city"
                value={formProps.values.nonVeterinarian.city}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage name="nonVeterinarian.city" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.nonVeterinarian.zip
                && formProps.errors.nonVeterinarian.zip
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="nonVeterinarian.zip">Zip Code *</label>
              <input
                id="nonVeterinarian.zip"
                type="number"
                name="nonVeterinarian.zip"
                value={formProps.values.nonVeterinarian.zip}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage name="nonVeterinarian.zip" component="div" className="error-message" />
            </div>
          </GridItem>
        </Grid>
      </Card>
    </Fragment>
  );
};

const FormAsVet = (formAsVetProps) => {
  const { states, formProps } = formAsVetProps;
  const basicConditional = formProps.touched.vetProfile && formProps.errors.vetProfile;

  return (
    <Fragment>
      <Card>
        <Grid>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.clinicName
                && formProps.errors.vetProfile.clinicName
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="clinicName">Name of Veterinary Clinic/Practice *</label>
              <input
                id="clinicName"
                type="text"
                name="vetProfile.clinicName"
                value={formProps.values.vetProfile.clinicName}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="vetProfile.clinicName"
                component="div"
                className="error-message"
              />
            </div>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.clinicStreet
                && formProps.errors.vetProfile.clinicStreet
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.clinicStreet">Street *</label>
              <input
                id="vetProfile.clinicStreet"
                type="text"
                name="vetProfile.clinicStreet"
                value={formProps.values.vetProfile.clinicStreet}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="vetProfile.clinicStreet"
                component="div"
                className="error-message"
              />
            </div>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.clinicState
                && formProps.errors.vetProfile.clinicState
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.clinicState">State *</label>
              <select
                id="vetProfile.clinicState"
                name="vetProfile.clinicState"
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
                defaultValue=""
              >
                <option value="" disabled hidden>
                  Choose state here
                </option>
                {states.map((item, index) => (
                  <option value={item.strapiId} key={index.toString()}>
                    {item.name}
                  </option>
                ))}
              </select>
              <ErrorMessage
                name="vetProfile.clinicState"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.clinicCity
                && formProps.errors.vetProfile.clinicCity
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.clinicCity">City *</label>
              <input
                id="vetProfile.clinicCity"
                type="text"
                name="vetProfile.clinicCity"
                value={formProps.values.vetProfile.clinicCity}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="vetProfile.clinicCity"
                component="div"
                className="error-message"
              />
            </div>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.clinicZip
                && formProps.errors.vetProfile.clinicZip
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.clinicZip">Zip Code *</label>
              <input
                id="vetProfile.clinicZip"
                type="number"
                name="vetProfile.clinicZip"
                value={formProps.values.vetProfile.clinicZip}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage name="vetProfile.clinicZip" component="div" className="error-message" />
            </div>
          </GridItem>
        </Grid>
      </Card>
      <Card>
        <Grid>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.licenseNumber
                && formProps.errors.vetProfile.licenseNumber
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.licenseNumber">Veterinary License Number *</label>
              <input
                id="vetProfile.licenseNumber"
                type="text"
                name="vetProfile.licenseNumber"
                value={formProps.values.vetProfile.licenseNumber}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="vetProfile.licenseNumber"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.licenseExpiration
                && formProps.errors.vetProfile.licenseExpiration
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.licenseExpiration">Veterinary License Expiration *</label>
              <input
                id="vetProfile.licenseExpiration"
                type="date"
                name="vetProfile.licenseExpiration"
                value={formProps.values.vetProfile.licenseExpiration}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="vetProfile.licenseExpiration"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
          <GridItem sm={12}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.licenseFile
                && formProps.errors.vetProfile.licenseFile
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.licenseFile">Veterinary License attachment *</label>
              <input
                type="file"
                id="vetProfile.licenseFile"
                accept="application/pdf, .jpg, .jpeg, .png"
                name="vetProfile.licenseFile"
                onChange={(e) => {
                  formProps.setFieldValue('vetProfile.licenseFile', e.currentTarget.files[0]);
                }}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage
                name="vetProfile.licenseFile"
                component="div"
                className="error-message"
              />
            </div>
          </GridItem>
        </Grid>
      </Card>
      <Card>
        <Grid>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.deaNumber
                && formProps.errors.vetProfile.deaNumber
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.deaNumber">DEA Number</label>
              <input
                id="vetProfile.deaNumber"
                type="text"
                name="vetProfile.deaNumber"
                value={formProps.values.vetProfile.deaNumber}
                onChange={formProps.handleChange}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage name="vetProfile.deaNumber" component="div" className="error-message" />
            </div>
          </GridItem>
          <GridItem sm={6}>
            <div
              className={`form-group ${
                basicConditional
                && formProps.touched.vetProfile.deaFile
                && formProps.errors.vetProfile.deaFile
                  ? 'form-group-invalid'
                  : ''
              }`}
            >
              <label htmlFor="vetProfile.deaFile">DEA Number attachment</label>
              <input
                type="file"
                accept="application/pdf, .jpg, .jpeg, .png"
                id="vetProfile.deaFile"
                name="vetProfile.deaFile"
                onChange={(e) => {
                  formProps.setFieldValue('vetProfile.deaFile', e.currentTarget.files[0]);
                }}
                onBlur={formProps.handleBlur}
                className="form-control"
              />
              <ErrorMessage name="vetProfile.deaFile" component="div" className="error-message" />
            </div>
          </GridItem>
        </Grid>
      </Card>
    </Fragment>
  );
};

const RegisterForAccessForm = (formProps) => {
  const customHandleChange = (e) => {
    formProps.resetForm();
    formProps.setFieldValue('role', e.target.value);
  };

  return (
    <StaticQuery
      query={graphql`
        query {
          allStrapiState {
            edges {
              node {
                name
                strapiId
              }
            }
          }
        }
      `}
      render={(data) => {
        const states = data.allStrapiState ? formatGraphqlData(data.allStrapiState) : [];
        return (
          <Form className="c-online-refills-form" noValidate>
            <Grid>
              <GridItem sm={6}>
                <div className="form-radio-btn">
                  <input
                    type="radio"
                    name="role"
                    value={ROLE_VET}
                    onChange={e => customHandleChange(e)}
                    onBlur={formProps.handleBlur}
                    className="form-control"
                    id="role-1"
                  />
                  <label htmlFor="role-1"> I am a Veterinarian</label>
                </div>
              </GridItem>
              <GridItem sm={6}>
                <div className="form-radio-btn">
                  <input
                    type="radio"
                    name="role"
                    value={NON_VETERINARIAN}
                    onChange={e => customHandleChange(e)}
                    onBlur={formProps.handleBlur}
                    className="form-control"
                    id="role-2"
                  />
                  <label htmlFor="role-2">I am not a Veterinarian</label>
                </div>
              </GridItem>
            </Grid>

            {formProps.values.role !== '' && (
              <Card>
                <Grid>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.firstName && formProps.errors.firstName
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="firstName">First Name *</label>
                      <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        value={formProps.values.firstName}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="firstName" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.lastName && formProps.errors.lastName
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="lastName">Last Name *</label>
                      <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        value={formProps.values.lastName}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="lastName" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.phoneNumber && formProps.errors.phoneNumber
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="phoneNumber">Phone *</label>
                      <input
                        id="phoneNumber"
                        type="tel"
                        name="phoneNumber"
                        value={formProps.values.phoneNumber}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.email && formProps.errors.email
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="email">Email *</label>
                      <input
                        id="email"
                        type="email"
                        name="email"
                        value={formProps.values.email}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="email" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.password && formProps.errors.password
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="password">Password *</label>
                      <input
                        id="password"
                        type="password"
                        name="password"
                        value={formProps.values.password}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="password" component="div" className="error-message" />
                    </div>
                  </GridItem>
                  <GridItem sm={6}>
                    <div
                      className={`form-group ${
                        formProps.touched.password2 && formProps.errors.password2
                          ? 'form-group-invalid'
                          : ''
                      }`}
                    >
                      <label htmlFor="password2">Confirm password *</label>
                      <input
                        id="password2"
                        type="password"
                        name="password2"
                        value={formProps.values.password2}
                        onChange={formProps.handleChange}
                        onBlur={formProps.handleBlur}
                        className="form-control"
                      />
                      <ErrorMessage name="password2" component="div" className="error-message" />
                    </div>
                  </GridItem>
                </Grid>
              </Card>
            )}

            {(formProps.values.role === String(ROLE_VET) && (
              <FormAsVet states={states} formProps={formProps} />
            ))
              || (formProps.values.role === String(NON_VETERINARIAN) && (
                <FormAsNotVet states={states} formProps={formProps} />
              ))}

            {formProps.isSubmitting && <Loader text="Please wait ..." />}

            {formProps.status && formProps.status.error && (
              <Snackbar
                message="There was an error submitting the form."
                show
                type="danger"
                handleDismiss={() => {}}
              />
            )}

            {formProps.values.role !== '' && (
              <div className="form-group">
                <button
                  type="submit"
                  disabled={formProps.isSubmitting}
                  className="c-button--primary float-right"
                >
                  SUBMIT
                </button>
              </div>
            )}
          </Form>
        );
      }}
    />
  );
};

class RegisterForAccessPage extends Component {
  state = {
    isSubmitting: false,
    hasErrors: false,
    errorMessage: 'There was an error submitting the form.',
  };

  handleSubmit = async (values, { setSubmitting }) => {
    try {
      // eslint-disable-next-line no-undef
      const payload = new FormData();

      const { nonVeterinarian, vetProfile, ...rest } = values;
      const { deaFile, licenseFile, ...restVet } = vetProfile;

      Object.keys(rest).forEach((restKey) => {
        payload.append(restKey, rest[restKey]);
      });

      nonVeterinarian.actualRole = nonVeterinarian.otherRole !== '' ? nonVeterinarian.otherRole : nonVeterinarian.actualRole;

      payload.append('nonVeterinarian', JSON.stringify(nonVeterinarian));
      payload.append('vetProfile', JSON.stringify(restVet));

      payload.append('deaFile', deaFile);
      payload.append('licenseFile', licenseFile);
      payload.append('username', rest.email);

      await Api.post('/local/users/registration/', payload, {
        headers: { 'Content-Type': 'multipart/form-data;' },
      });

      setSubmitting(false);
      this.setState({ isSubmitting: false });
      navigate('/thank-you/');
    } catch (error) {
      setSubmitting(false);
      if (error.response && error.response.data && error.response.data.message) {
        this.setState({
          isSubmitting: false,
          hasErrors: true,
          errorMessage: error.response.data.message,
        });
      } else {
        this.setState({ isSubmitting: false, hasErrors: true });
      }
    }
  };

  handleDismiss = () => {
    this.setState({ hasErrors: false });
  };

  render() {
    const { isSubmitting, hasErrors, errorMessage } = this.state;
    return (
      <div>
        {isSubmitting && <Loader text="Please wait ..." />}
        <Layout title="Register for Access">
          <Hero backgroundImage="zoo-pharm" headline="Register for Access" />
          <section className="content-wrapper">
            <Grid justifyContent="center" alignItems="center">
              <GridItem md={8}>
                <h2 className="h-text-center">Sign Up</h2>
                <h3>
                  Register Now
                  <br />
                  Complete this form to create an account.
                </h3>

                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={this.handleSubmit}
                  render={formProps => <RegisterForAccessForm {...formProps} />}
                />
              </GridItem>
            </Grid>
          </section>
        </Layout>
        <Snackbar
          message={`Error: ${errorMessage}`}
          show={hasErrors}
          type="danger"
          handleDismiss={this.handleDismiss}
        />
      </div>
    );
  }
}

export default RegisterForAccessPage;
